








































































import marked from "marked";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Categories, Item, Items } from "@/types";
import { namespace } from "vuex-class";
const AppModule = namespace("AppModule");
const UserModule = namespace("UserModule");
const CategoriesModule = namespace("CategoriesModule");
const ItemModule = namespace("ItemModule");
@Component({
  components: { Stepper: () => import("@/components/Stepper/Stepper.vue") }
})
export default class VItem extends Vue {
  //
  //
  // PROPS
  @Prop() readonly item!: number;
  //
  //
  // COMPUTED
  @AppModule.State
  public switchingLanguage!: boolean;
  @UserModule.State
  public points!: number;
  @CategoriesModule.State
  public items!: Items;
  @CategoriesModule.State
  public categories!: Categories;
  @ItemModule.State
  public fields!: {};
  get currentItemId(): number {
    return this.$store.state.ItemModule.current;
  }
  get current(): Item {
    const res = this.items.find(({ id }) => {
      return id === this.currentItemId;
    });
    return res ? res : this.items[0];
  }
  get priceProvided() {
    return this.current.price >= 0;
  }
  get markdownDescription() {
    try {
      return marked.parse(this.current.description);
    } catch (e) {
      return null;
    }
  }
  //
  //
  // DATA
  private successDialog = false;
  //
  //
  // METHODS
  onSubmitted() {
    this.successDialog = true;
  }
  async onDialogBtn() {
    await this.$router.push("/profile");
    await this.$store.dispatch("CategoriesModule/updateItems");
  }
  //
  //
  // LIFECYCLE
  beforeDestroy() {
    localStorage.setItem(
      "cached-fields",
      JSON.stringify({
        item: this.currentItemId,
        fields: this.fields
      })
    );
  }
  async created() {
    const preload = this.currentItemId !== this.item;
    const n = Number(this.item);
    if (isNaN(n) || !Number.isInteger(n) || n < 0) {
      await this.$router.replace({ name: "404" });
      return;
    }
    if (this.items.length < 1)
      await this.$store.dispatch("CategoriesModule/updateItems");
    this.$store.commit("ItemModule/CURRENT", this.item);
    if (
      !this.items.find(({ id }) => {
        return id === this.currentItemId;
      })
    ) {
      await this.$router.replace({ name: "404" });
    }
    if (preload) {
      let data: any = localStorage.getItem("cached-fields");
      data = data ? JSON.parse(data) : {};
      const fields = data.fields && data.item === this.item ? data.fields : {};
      await this.$store.dispatch("ItemModule/preload", {
        fields
      });
    }
  }
}
